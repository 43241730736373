import * as React from "react"
import Layout from "../../components/layout";
import Hero from "../../components/hero";

import {Container, Typography} from "@mui/material";


// markup
const ContactPage = () => {
    return (
        <Layout title={"Contact"}>
            <main>
                <Hero title={"Contact"}
                      subtitle={"Get in touch with our team"}/>

                <Container style={{paddingTop: "3em"}}>
                    <Typography variant={"h5"} color={"primary"}>
                        Hello! How can we help?
                    </Typography>
                    <Typography>
                        If you have comments, questions, or would like to get in touch, please reach out directly to support by email at <a href={"mailto:support@juryboxapp.com"}>support@juryboxapp.com</a>.
                    </Typography>
                    <br/>
                    <Typography>
                        We look forward to hearing from you!
                    </Typography>
                </Container>
            </main>
        </Layout>
    );
};

export default ContactPage;
